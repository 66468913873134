<template>
    <div class="main">
        <div class="spinner">
            <div class="rect1 line"></div>
            <div class="rect2 line"></div>
            <div class="rect3 line"></div>
            <div class="rect4 line"></div>
            <div class="rect5 line"></div>
        </div>
        <div class="center-text">加载中</div>
    </div>
</template>

<script>
    // import Cookies from "js-cookie";
    import {
        wechatLogin
    } from '@/api/app'
    export default {
        name: 'Login',
        data() {
            return {
                code:""
            }},
        created() {
            this.getCode()
        },
        mounted:function(){
        },
        methods: {
            getUrlCode() {
                var qs = (location.search.length > 0 ? location.search.substring(1) : ""),
                    //保存数据的对象
                    args = {},
                    //取得每一项
                    items = qs.length ? qs.split("&") : [],
                    item = null,
                    name = null,
                    value = null,
                    //在for 循环中使用
                    i = 0,
                    len = items.length;
                //逐个将每一项添加到args 对象中
                for (i=0; i < len; i++){
                    item = items[i].split("=");
                    name = decodeURIComponent(item[0]);
                    value = decodeURIComponent(item[1]);
                    if (name.length) {
                        args[name] = value;
                    }
                }
                return args;
            },
            // 获取code
            getCode() {
                // 非静默授权，第一次有弹框
                var local = window.location.href; // 获取页面url
                var appid = 'wxb82fee11290ca0e6';
                var url = encodeURIComponent(local);
                var code =this.getUrlCode();
                if (!code.code) {
                    // 如果没有code，则去请求
                    window.location.href =
                        'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
                        appid +
                        '&redirect_uri=' +
                        url +
                        '&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect';
                } else {
                    let that = this
                    that.getUserInfo(code.code)
                }
            },
            // 获取用户信息
            getUserInfo(code){
                let postData = {}
                postData.code = code
                wechatLogin(postData).then(res => {
                    if (res.code == 200) {
                        sessionStorage.setItem('token',res.data.token)
                        sessionStorage.setItem('userInfo',JSON.stringify(res.data))
                        this.$router.push({path: '/home'})
                    } else {
                        this.$message({message: res.msg, type: 'error',duration: 3000});
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .main{
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #55aaff;
    }
    .center-text{
        font-size: 72px;
        color: #ffffff;
        margin-top: 60px;
    }
    .spinner {
        width: 400px;
        height: 480px;
        text-align: center;
        font-size: 40px;
    }

    .spinner .line {
        background-color: #ffffff;
        margin-left: 40px;
        height: 100%;
        width: 40px;
        display: inline-block;

        -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
        animation: stretchdelay 1.2s infinite ease-in-out;
    }

    .spinner .rect2 {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
    }

    .spinner .rect3 {
        -webkit-animation-delay: -1.0s;
        animation-delay: -1.0s;
    }

    .spinner .rect4 {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
    }

    .spinner .rect5 {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
    }

    @-webkit-keyframes stretchdelay {
        0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
        20% { -webkit-transform: scaleY(1.0) }
    }

    @keyframes stretchdelay {
        0%, 40%, 100% {
            transform: scaleY(0.4);
            -webkit-transform: scaleY(0.4);
        }  20% {
               transform: scaleY(1.0);
               -webkit-transform: scaleY(1.0);
           }
    }
</style>
